<template>
  <div class="registerContainer">
    <div class="register-wrap container">
      <b-form @submit.prevent="submit" class="registerform">
        <b-row>
					<b-col style="display: flex; justify-content: flex-start">
						<img class="back" src="../assets/back.svg" @click="$router.push('/')" />
					</b-col>
				</b-row>
        <b-row style="margin-bottom: 10px">
          <b-col md="12">
            <div class="form-group">
              <label for="LegalName" class="control-label"
                >Registered Legal Name</label
              >
              <b-form-input
                id="LegalName"
                v-model.lazy="$v.register.legalname.$model"
                class="form-control input-focus"
                placeholder="Enter your Legal Name"
              ></b-form-input>
            </div>
          </b-col>
        </b-row>
        <b-row style="margin-bottom: 10px">
          <b-col md="12">
            <label for="CompanyName">Company Name</label>
            <b-form-input
              id="CompanyName"
              class="form-control input-focus"
              v-model="$v.register.companyname.$model"
              placeholder="Enter your Company Name"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <label for="MobileNumber" description="error">Mobile Number</label>
          </b-col>
          <b-col md="2">
            <select v-model.lazy="register.countryCode" class="selection">
              <option
                v-for="(option, index) in options"
                :key="index"
                v-bind:value="option.value"
              >
                {{ option.value }}
              </option>
            </select>
          </b-col>
          <b-col md="6">
            <b-form-input
              type="number"
              id="MobileNumber"
              class="form-control input-focus"
              v-model.lazy="register.mobilenumber"
              placeholder="Mobile Number"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row style="margin-bottom: 10px">
          <b-col md="12">
            <label for="email">Email</label>
          </b-col>
          <b-col md="12">
            <b-form-input
              id="email"
              class="form-control input-focus"
              v-model="$v.register.emailaddr.$model"
              placeholder="Email Address"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row style="margin-bottom: 10px">
          <b-col md="4">
            <label for="licenseno">License No</label>
          </b-col>
          <b-col md="3">
            <b-form-input
              id="licenseno"
              class="form-control input-focus"
              v-model="$v.register.licenseNo.$model"
              placeholder="License No"
            ></b-form-input>
          </b-col>
          <b-col md="2">
            <label for="licenseExpiry">License Expiry</label>
          </b-col>
          <b-col md="3">
            <b-form-datepicker
              id="licenseExpiry"
              type="text"
              class="form-control input-focus"
              locale="en-US"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              v-model="$v.register.licenseExpiry.$model"
              placeholder="MM-DD-YYYY"
            ></b-form-datepicker>
          </b-col>
        </b-row>
        
        <b-row style="margin-bottom: 10px">
          <b-col md="4">
            <label for="address">Registered Address</label>
          </b-col>
          <b-col md="8">
            <b-form-input
              id="address"
              class="form-control input-focus"
              v-model="$v.register.address.$model"
              placeholder="Registered Address"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row style="margin-bottom: 10px">
          <b-col md="4">
            <label for="pobox">P.O.Box</label>
          </b-col>
          <b-col md="8">
            <b-form-input
              type="number"
              id="pobox"
              class="form-control input-focus"
              v-model="$v.register.pobox.$model"
              placeholder="Registered P.O.Box"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row style="margin-bottom: 10px">
          <b-col md="4">
            <label for="naddr">Country Address</label>
          </b-col>
          <b-col md="8">
            <b-form-input
              id="naddr"
              class="form-control input-focus"
              v-model="$v.register.naddr.$model"
              placeholder="National Address"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row style="margin-bottom: 10px">
          <b-col md="2">
            <label for="city">City</label>
          </b-col>
          <b-col md="4">
            <b-form-input
              id="city"
              class="form-control input-focus"
              v-model="$v.register.city.$model"
              placeholder="City"
            ></b-form-input>
          </b-col>
          <b-col md="2">
            <label for="city">Country</label>
          </b-col>
          <b-col md="4">
            <b-form-input
              id="city"
              class="form-control input-focus"
              v-model="$v.register.country.$model"
              placeholder="Country"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row style="margin-bottom: 20px">
          <b-col md="4">
            <label for="website">Website</label>
          </b-col>
          <b-col md="8">
            <b-form-input
              id="website"
              class="form-control input-focus"
              v-model="$v.register.website.$model"
              placeholder="Company Website"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row style="margin-bottom: 20px">
          <b-col md="12">
            <b-button class="submit pb-2" variant="dark" type="submit"
              >Next</b-button
            >
          </b-col>
        </b-row>
        <b-row class="error">
          <b-col style="text-align: center">
            <span
              class="text-danger"
              v-if="
                !$v.register.legalname.required && $v.register.legalname.$error
              "
              >Legal Name is Required</span
            >
            <span
              class="text-danger"
              v-else-if="
                $v.register.legalname.$params.minLength &&
                $v.register.legalname.$error
              "
              >Minimum 8 characters are required</span
            >
            <span
              class="text-danger"
              v-else-if="
                !$v.register.companyname.required &&
                $v.register.companyname.$error
              "
              >Company Name is Required</span
            >
            <span
              class="text-danger"
              v-else-if="
                $v.register.companyname.$params.minLength &&
                $v.register.companyname.$error
              "
              >Minimum 8 characters are required</span
            >
            <span class="text-danger" v-else-if="$v.register.countryCode.$error"
              >Please Select your Country Code</span
            >
            <span
              class="text-danger"
              v-else-if="
                !$v.register.mobilenumber.required &&
                $v.register.mobilenumber.$error
              "
              >Mobile Number is required</span
            >
            <span
              class="text-danger"
              v-else-if="
                $v.register.mobilenumber.$params.maxLength &&
                $v.register.mobilenumber.$error
              "
              >Mobile number should be of
              {{ $v.register.mobilenumber.$params.maxLength.max }} digits</span
            >
            <span
              class="text-danger"
              v-else-if="
                !$v.register.emailaddr.required && $v.register.emailaddr.$error
              "
              >Email Address is required</span
            >
            <span
              class="text-danger"
              v-else-if="
                !$v.register.emailaddr.email && $v.register.emailaddr.$error
              "
              >Email Address is invalid</span
            >
            <span
              class="text-danger"
              v-else-if="
                !$v.register.licenseNo.required && $v.register.licenseNo.$error
              "
              >License No is required</span
            >
            <span
              class="text-danger"
              v-else-if="
                !$v.register.licenseExpiry.required && $v.register.licenseExpiry.$error
              "
              >License Expiry is required</span
            >
            <span
              class="text-danger"
              v-else-if="
                !$v.register.address.required && $v.register.emailaddr.$error
              "
              >Registered Address is required</span
            >
            <span
              class="text-danger"
              v-else-if="
                $v.register.address.$params.minLength &&
                $v.register.address.$error
              "
              >Minimum 8 characters are required
            </span>
            <span
              class="text-danger"
              v-else-if="
                !$v.register.pobox.required && $v.register.pobox.$error
              "
              >P.O.Box Number is required</span
            >
            <span
              class="text-danger"
              v-else-if="
                !$v.register.naddr.required && $v.register.naddr.$error
              "
              >Home Country Address is required</span
            >
            <span
              class="text-danger"
              v-else-if="!$v.register.city.required && $v.register.city.$error"
              >City is required</span
            >
            <span
              class="text-danger"
              v-else-if="
                !$v.register.country.required && $v.register.country.$error
              "
              >Country is required</span
            >
            <span
              class="text-danger"
              v-else-if="
                !$v.register.website.required && $v.register.website.$error
              "
              >Website is required</span
            >
          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  email,
} from "vuelidate/lib/validators";

export default {
  data() {
    return {
      register: {
        legalname: "",
        companyname: "",
        mobilenumber: "",
        countryCode: "",
        emailaddr: "",
        licenseNo: "",
        licenseExpiry: "",
        address: "",
        pobox: "",
        naddr: "",
        city: "",
        country: "",
        website: "",
      },
      options: [
        { id: 1, Country: "SAUDI", value: "966" },
        { id: 2, Country: "UAE", value: "971" },
        { id: 3, Country: "INDIA", value: "91" },
        { id: 4, Country: "INDONESIA", value: "61" },
      ],
    };
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.register.licenseExpiry = this.register.licenseExpiry.split("-").reverse().join("")
        this.$store.dispatch("register/register", this.register);
        this.$router.push("/registernext");
      }
    },
  },

  validations: {
    register: {
      legalname: {
        required,
        minLength: minLength(8),
      },
      companyname: {
        required,
        minLength: minLength(8),
      },
      mobilenumber: {
        required,
        maxLength: maxLength(9),
        minLength: minLength(9),
      },
      countryCode: {
        required,
      },
      emailaddr: {
        required,
        email,
      },
      licenseNo: {
        required
      },
      licenseExpiry: {
        required
      },
      address: {
        required,
        minLength: minLength(8),
      },
      pobox: {
        required,
      },
      naddr: {
        required,
      },
      city: {
        required,
      },
      country: {
        required,
      },
      website: {
        required,
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.registerContainer {
  padding: 0;
  height: 100vh;
  width: 100%;
  font-family: "Gilroy", sans-serif;

  .register-wrap {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .registerform {
    padding: 10% 5% 5% 5%;
    background: white;
    -webkit-box-shadow: 0px 3px 9px 0px rgba(220, 220, 220, 1);
    -moz-box-shadow: 0px 3px 9px 0px rgba(220, 220, 220, 1);
    box-shadow: 0px 3px 9px 0px rgba(220, 220, 220, 1);

    .back {
      height: 30px;
      cursor: pointer;
      margin-bottom: 20px;
    }
    .back:hover {
      -webkit-box-shadow: 0px 3px 9px 0px rgb(231, 231, 231);
      -moz-box-shadow: 0px 3px 9px 0px rgb(236, 236, 236);
      box-shadow: 0px 3px 9px 0px rgb(238, 238, 238); 
    }

    .back:focus {
      outline: none!important;
    }
  }

  .text-danger {
    transition: all 500ms ease-in-out;
    -webkit-transition: all 500ms ease-in-out;
    -mox-transition: all 500ms ease-in-out;
  }

  .selection {
    width: 100%;
    padding: 9% 0px;
  }

  .submit {
    width: 100%;
  }

  .input-focus {
    border-radius: 4px;
  }

  .input-focus:focus {
    outline: none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    border-color: #121212;
    transition: all 500ms ease-in;
  }
}
</style>
